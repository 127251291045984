<!-- 新增编辑体验用户 -->
<template>
    <div class='box-card'>
          <el-form :model="batchAddForm" :rules="batchAddFormRules" ref="batchAddFormRef" label-width="120px"
            class="form_box">
            <el-form-item label="手机号：" prop="phone">
              <el-input placeholder="请输入手机号" v-model="batchAddForm.phone" maxlength="11" @input="(e) => (batchAddForm.phone = validSpace(e))"></el-input>
            </el-form-item>
             <el-form-item label="公司名称：" prop="merchant_name">
              <el-input v-model="batchAddForm.company" placeholder="请输入公司名称" maxlength="30" @input="(e) => (batchAddForm.company = validSpace(e))"></el-input>
            </el-form-item>
               <el-form-item label="负责人：" prop="principal">
              <el-input placeholder="请输入负责人姓名" v-model="batchAddForm.principal" maxlength="10" @input="(e) => (batchAddForm.principal = validSpace(e))"></el-input>
            </el-form-item>
        </el-form>
        <lbButton type="default" fill icon="confirm" class="checkButton" @click="submitAddForm" v-preventReClick>保 存</lbButton>
    </div>
</template>

<script>
import { updateAIExperienceCounter, addAIExperienceCounter, getAIAccountInfoDetial } from '@/api/maintenanceCenter'
export default {
  name: '',
  components: {},
  props: ['id'],
  data () {
    return {
      batchAddForm: {
        phone: '',
        company: '',
        principal: ''
      },
      batchAddFormRules: {
        phone: [{ required: true, message: '请输入手机号', trigger: 'blur' }, { pattern: /^1[3456789]\d{9}$/, message: '请输入正确的手机号', trigger: 'blur' }]
      }
    }
  },
  watch: {},
  computed: {},
  methods: {
    // 获取用户详情
    async fnGetCounterDetial () {
      const res = await getAIAccountInfoDetial({ _id: this.id })
      console.log('用户详情', res)
      if (res.Data && res.Code === 200) {
        this.batchAddForm.phone = res.Data.phone
        this.batchAddForm.company = res.Data.company
        this.batchAddForm.principal = res.Data.principal
      }
    },
    // 保存
    submitAddForm () {
      // 对整个表单进行校验
      this.$refs.batchAddFormRef.validate((valid) => {
        // 成功
        if (valid) {
          if (this.id) {
            this.updateCounter()
          } else {
            this.addCounter()
          }
        } else {
          return false
        }
      })
    },
    // 新增用户
    async addCounter () {
      const res = await addAIExperienceCounter(this.batchAddForm)
      if (res && res.Code === 200) return this.$emit('closeDialog', false)
    },
    // 编辑用户
    async  updateCounter () {
      this.batchAddForm._id = this.id
      const res = await updateAIExperienceCounter(this.batchAddForm)
      if (res && res.Code === 200) return this.$emit('closeDialog', false)
    }
  },
  created () {

  },
  mounted () {
    if (this.id) {
      // 获取用户详情
      this.fnGetCounterDetial()
    }
  }
}
</script>

<style scoped lang="less">
.box-card {
    position: relative;
    padding-bottom: 30px;
    .form_box{
      max-height: 650px;
    overflow-y: auto;
    overflow-x: hidden;
    }
    .checkButton {
        position: absolute;
        right: 0px;
    }

    .checkButton2 {
        position: absolute;
        right: 100px;
    }
}
</style>
